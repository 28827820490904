import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import store from './store'
import axios from 'axios'
import socket_ from './util/socket'
import VueCookies from 'vue3-cookies'
// import lazyLoad from 'vue-lazyload'

// const loadingImg = require('./assets/logo.png')


//configuration axios
//'http://192.168.88.254:4044' //Sur serveur
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'http://localhost:4044' //'http://localhost:4044' 


const app = createApp(App)
app.use(store)
app.use(socket_)
app.use(VueCookies, {
        expireTimes: "30d",
        path: "/",
        domain: "",
        secure: false,
        sameSite: "None"
    })
    // app.use(lazyLoad, {
    //     preLoad: 1.3,
    //     loading: loadingImg,
    //     attempt: 1
    // })
app.config.globalProperties.$http = axios
app.use(router)
app.mount('#app')

// var k=[]
// k.