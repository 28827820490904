<template>
    <div class="  "> 
        <div v-if="$route.name=='abonnement'" class=" flex flex-row ">
            <liste_prod_></liste_prod_>
            <liste_fourn_></liste_fourn_>
        </div> 
        <router-view class="" v-else></router-view>
    </div>
    </template>
      
    <script>
    import liste_prod_ from './liste/listeProduit_.vue'
    import liste_fourn_ from './liste/listeFourn_.vue'
    export default {
        components: {
            liste_prod_,
            liste_fourn_
        }
    }
    </script> 
    <style>
            
    </style>
    