import { createStore } from "vuex";

import router from '../router'
import axios from 'axios'

export default createStore({
    state: {
        theme_dark: true,
        showMenu: false,
        searchBar: false,
        showFrame: false,
        showMenuList:false,
        showTendance:false,
        // scrool dans prod
        scrollPosition:0,
        indexOfPage: 16,
        // loaded:true,
        logged: null,
        // liste des fournisseurs
        listFourn:{},
        fournisseur: {},
        client_:{},
        // liste des prodiuts vendus 
        categorie: {},
        _my_cat: [],
        sous_categorie: {},  
        disp_: { tot_: 0, pub_: 0, not_: 0 },
        show_notif: { show_: false, message_: '', successType_: false },
        imgTemp: [
            [''],
            ['', '', ''], ''
        ],
        show_mdp_cofirm: [false, false, false],
        //save image
        save_img: {
            show_: false,
            indexing: 0,
            end: 0,
            message: 'importation',
            success: false
        },
        index: 0,
        getItemOfProd_: {},
        listeProd_: [{}, null],
        liste_prod_fourn: [{}, null],
        isLoggedIn:false,
        singleProd_: {},
        tendeceListe:{}
    },
    getters: {},
    mutations: {

        async enreg_(state, array) {
            try {
                switch (array.methode) {
                    case 'post':
                        await axios.post(array.url, array.data)
                            .then((e) => {
                                state.show_notif.show_ = true;
                                state.show_notif.message_ = e.data.message;
                                state.show_notif.successType_ = e.data.status;
                                if (e.data.reload) {
                                    window.location='/'
                                }
                            })
                        break;
                    case 'put':
                        await axios.put(array.url, array.data).catch((e) => { console.log(e); })
                        break;
                    case 'get':
                        await axios.get(array.url)
                        break;
                    case 'delete':
                        await axios.delete(array.url, { data: array.data, headers: { Autorization: '*' } }).then((d) => {

                            state.isLoggedIn = false;
                            state.show_notif.show_ = true
                            state.show_notif.successType_ = false
                            state.show_notif.message_ = d.data.message
                            this.commit('chek_', {
                                methode: 'get',
                                url: 'api/check',
                                data: {}
                            })
                            router.go(-1)
                        }).catch((e) => { console.log(e); })
                        break;
                    default:
                        break;
                }
            } catch (e) {
                e
                // console.log('Erreur de connexion', e)
            }
        },
        async login_(state, array) {
            await axios.post(array.url, array.data).then((data) => {
                if (data.data.status == false) {
                    state.show_notif.show_ = true
                    state.show_notif.successType_ = false
                    state.show_notif.message_ = data.data.message
                    if (data.reload) {
                        window.location='/'
                    }
                }
            })
        },
        async chek_(state, array) {
            try {
                if (array.methode == 'post') {
                    await axios.post(array.url, array.data).then((d) => {
                        // console.log(d);
                      
                        if (d.data.reload) {
                            window.location='/'
                        }
                    })
                } else {
                    await axios
                        .get(array.url)
                        .then((d) => {

                            if (d.status) {
                                if (d.data.status == true) {
                                    state.isLoggedIn = true;
                                    state.fournisseur = d.data.data
                                    // console.log(d.data.data);

                                    state.show_notif.successType_ = true
                                } else if (d.data.status == 401) {
                                    state.isLoggedIn = false;
                                    state.show_notif.show_ = true
                                    state.show_notif.successType_ = false
                                    state.show_notif.message_ = d.data.message
                                } else if (d.data.status == 403) { 
                                    // state.show_mdp_cofirm[2] = true;
                                    state.isLoggedIn = false; 
                                    // state.show_notif.show_ = true
                                    // state.show_notif.successType_ = false
                                    // state.show_notif.message_ = d.data.message
                                } else {
                                    state.isLoggedIn = false
                                }
                            }
                        })
                }
            } catch (e) {
                console.log('Erreur de connexion')
                console.log(e);
            }
        },
        async getOne_(state, array) {
            try {
                const _r = await axios.post(array.url)
                let _d = _r.data
                if (_d.status) {
                    console.log(_d);
                }
            } catch (e) {
                console.log('Erreur de connexion')
            }
        },
        async uploadFile(state, array) {
            try {
                let _r = null;
                if (array[1] == 'bg') {
                    let formData = new FormData();
                    formData.append("usr-img", array[0]);
                    _r = await axios.post('api/imgBg', formData)
                    if (_r.data.status) {
                        this.commit('chek_', { url: 'api/login', methode: 'post', data: { fourn_tel: state.fournisseur.fourn_tel, fourn_pass: state.fournisseur.fourn_pass } })
                    }

                } else if (array[1] == 'logo') {
                    let formData = new FormData();
                    formData.append("usr-img", array[0]);
                    _r = await axios.post('api/img', formData)
                    if (_r.data.status) {
                        this.commit('chek_', { url: 'api/login', methode: 'post', data: { fourn_tel: state.fournisseur.fourn_tel, fourn_pass: state.fournisseur.fourn_pass } })
                    }
                } else if (array[1] == 'prod') {
                    state.save_img.show_ = true;
                    state.save_img.end = array[0].length;
                    for (let i = 0; i < array[0].length; i++) {
                        let formData = new FormData();
                        formData.append("usr-img", array[0][i]);
                        await axios.post('api/prod_img/' + array[2], formData).then(
                            () => {
                                state.save_img.indexing = i + 1
                                if (i + 1 == array[0].length) {
                                    state.save_img.message = 'Succées'
                                    state.save_img.success = true
                                    setTimeout(() => {
                                        setTimeout(() => {
                                            state.save_img.show_ = false;
                                            state.show_notif.show_ = true
                                            state.show_notif.successType_ = true
                                            state.show_notif.message_ = "Enregistrement Faite"
                                            router.go(-1)
                                        }, 1000);
                                    }, 1500);
                                    setTimeout(() => {
                                        state.save_img.success = false
                                        state.save_img.message = 'Importation'
                                    }, 1600);
                                }
                            }
                        )
                        //  req.io.emit('save_prod', { status: true, message: 'Faite' })
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },
        async update_img(state, array) {
            state.save_img.show_ = true;
            state.save_img.end = array.length;
            for (let i = 0; i < array.length; i++) {
                let formData = new FormData();
                formData.append("usr-img", array[i].file_);
                await axios.put('api/prod_img/' + array[i].id_, formData)
                state.save_img.indexing = i + 1
                if (i + 1 == array.length) {
                    state.save_img.message = 'Succées'
                    state.save_img.success = true
                    setTimeout(() => {
                        state.save_img.show_ = false;
                        state.show_notif.show_ = true
                        state.show_notif.successType_ = true
                        state.show_notif.message_ = "Mise à jour Faite"
                        router.go(-2)
                    }, 1500);
                    setTimeout(() => {
                        state.save_img.success = false
                        state.save_img.message = 'Importation'
                    }, 1600);
                }
            }
        },
        setLoggedIn(state,value){
            this.isLoggedIn=value
        }
    },
    actions: {},
    modules: {},
});