<template>
<div @scroll="checkScrolling_()" id="fournFrame_" class=" w-full h-full flex-col overflow-auto duration-1000">
    <header_ class=" z-10"></header_>
    <menu_Vue class=" z-30 sticky top-0" :is_Top="isTop_"></menu_Vue>
    <body_Vue class=" z-50" v-if="this.$store.state.liste_prod_fourn[1]!=0"></body_Vue>
</div>
</template>

<script>
 import header_ from './configuration/component_/header_.vue'
 import menu_Vue from './configuration/component_/menu_.vue'
 import body_Vue from './configuration/component_/body_.vue'
 export default {
     data() {
         return {
             isTop_: false,
         }
     },
     components: {
         header_,
         menu_Vue,
         body_Vue
     },
     methods: {
         checkScrolling_() {
             document.getElementById('fournFrame_').scrollTop * 100 / window.innerHeight >= 65 ? this.isTop_ = true : this.isTop_ = false;
         },
     },
     async mounted() {
         this.$store.state.imgTemp = [null, '', ''];
         await this.$http.get('api/produit/' + this.$route.params.id).then((resp) => {
             this.$store.state.liste_prod_fourn = [resp.data.reponse, resp.data.nb_total]
            
         })
         await this.$http.get('api/pers_categorie/' + this.$route.params.id).then((resp) => {
             this.$store.state.sous_categorie = resp
         }) 
     },

 }
</script>

<style scoped>
#nav a.router-link-exact-active span {
    color: #22c55e;
    font-weight: bold;
}

#nav a.router-link-exact-active path {
    fill: #22c55e;

}
</style>
