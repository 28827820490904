<template>
<div class=" flex  bg-neutral-900 border-r border-r-gray-800 py-3 ">
    <button @click="$store.state.showMenu?$store.state.showMenu=false:$store.state.showMenu=true" class="flex absolute sm:hidden right-2 px-1 ">
        <svg class="fill-current text-white w-7" viewBox="0 0 24 24">
            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>
    </button>
    <div class="flex absolute sm:hidden left-2 px-1 ">
        <div class="text-white"> Menus</div>
    </div>
    <div class=" flex w-full flex-col">
        <div class="flex flex-col mt-6">
            <div v-for=" item,i in listeNavBar" :key="i">

                <div v-if="i==1" class="flex flex-col  " @click="i==1?setMenu():''">
                    <div class="flex w-full px-3  py-2  border-l-4 border-transparent  flex-row">
                        <svg class=" w-6 fill-current text-stone-400" viewBox="0 0 24 24" v-html="item.ico"> </svg>
                        <span class=" ml-4 text-stone-400" v-text=" item.label"> </span>
                    </div>
                </div>
                <router-link v-if="i!=1" :to=" {name:item.name_}" class="flex flex-col  ">
                    <div class="flex w-full px-3  py-2  border-l-4 border-transparent  flex-row">
                        <svg class=" w-6 fill-current text-stone-400" viewBox="0 0 24 24" v-html="item.ico"> </svg>
                        <span class=" ml-4 text-stone-400" v-text=" item.label"> </span>
                    </div>
                </router-link>
                <div v-if="i==1 && this.$store.state.showMenuList==true" class="  overflow-y-auto h-[60vh] mt-2 max-h-full">
                    <router-link @click="getItId(item.fourn_id)" :to="{name:'thisFourn',query:{name:item.fourn_name,raiso:'all'},params:{id:item.fourn_id}}" v-for="item in this.$store.state.listFourn" :key="item.id" class="flex flex-col pl-5" :class="this.$route.params.id== item.fourn_id?' text-green-500':''">
                        <div class="border border-transparent bg-black group hover:border-green-500 mt-1 rounded-full px-2 py-1 flex flex-row items-center">
                            <span v-if="getImg(item.fourn_img_log_middle) !== ''">
                                <img :src="getImg(item.fourn_img_log_middle)" alt="" style="object-fit: cover" class="w-7 h-7 rounded-full" />
                            </span>
                            <span v-else>
                                <svg class="fill-current w-7 h-7 text-white" viewBox="0 0 24 24">
                                    <path d="M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10c0-5.53-4.5-10-10-10z" /></svg>
                            </span>
                            <span v-text="item.fourn_name" class="group-hover:text-green-500 text-sm pl-3 text-white"></span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            listeNavBar: [{
                    label: 'Accueil',
                    name_: 'produit',
                    ico: '<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8h5z" />'
                },
                {
                    label: 'Abonnement',
                    name_: 'abonnement',
                    ico: '<path d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9 3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3 3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3 3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.536 5.536 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13v-1.75M0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9-.59.68-.95 1.62-.95 2.65V20H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65 2.56.34 4.45 1.51 4.45 2.9V20z" />'
                },
                {
                    label: 'Préférences',
                    name_: 'profil',
                    ico: '<path d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2M1 2v2h2l3.6 7.59-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2z" />'
                },
            ],
        }
    },
    methods: {

        setMenu() {
            this.$store.state.showMenuList == true ? this.$store.state.showMenuList = false : this.$store.state.showMenuList = true
        },

        getImg(img) {
            try {
                return require(`../../../img/compressed/${img}`);
            } catch (e) {
                console.log(e); return ''
            }
        },
        async getItId(val) {
            await this.$http.get('api/produit/' + val).then((resp) => {
                this.$store.state.liste_prod_fourn = [resp.data.reponse, resp.data.nb_total]
            })
            await this.$http.get('api/pers_categorie/' + this.$route.params.id).then((resp) => {
                this.$store.state.sous_categorie = resp
            })
        }
    },
    mounted() {}
}
</script>

<style scoped>
div a.router-link-exact-active span {
    color: #22c55e;
    background: black;
}

a.router-link-exact-active div {
    background: black;
    border-left: 4px solid #22c55e;
    fill: #22c55e;
}

div a.router-link-exact-active svg {
    fill: #22c55e;

}

/* nav .router-link-exact-active{
  
  
} */
.border_ {
    border: 4px solid rgb(23, 23, 23)
}
</style>
