<template>
<div class=" text-white w-full h-full items-center flex justify-center flex-col ">
    <div class=" ">
        <logo  ></logo>
    </div>
    <span class="">Page instrouvable</span>
</div>
</template>

<script>
import logo from '../components/logo_.vue'
export default {
    components: {
        logo
    }
}
</script>
