import io from 'socket.io-client'
var socket = io('http://localhost:4044')
import store from '../store' 
// import cookies from '../util/util'


// // console.log(window.cookieStore);
// socket.on('categorie', (data) => {
//     var array = []
//     for (let i = 0; i < data.reponse.length; i++) {
//         const element = data.reponse[i];
//         array.push(`${element['cat_id']}`, `${element['sous_cat_id']}`, element.sous_cat_label)
//         array.push('][')
//     }
//     for (let i = 0; i < 2; i++) {
//         setTimeout(() => {

//             cookies.set('souca__', array);
//             store.state.categorie = data.reponse;
//         }, 200);
//     }
// })

socket.on('check_', (data) => { 
    // store.state.fournisseur = data;
    // store.state.fournisseur = data;
    store.state.show_notif.show_ = true;
    store.state.show_notif.message_ = 'Re Bonjour ' + data.client_nom;
    store.state.show_notif.successType_ = true;
    setTimeout(() => {
        window.location.reload()
    }, 400);
})

socket.on('sous_categorie', (data) => {
    store.state.sous_categorie = data.reponse;
})
// socket.on('all_product', (data) => {
//     console.log('socket:' +data.reponse);
//     store.state.liste_prod_fourn = [data.reponse, data.nb_total] 
// })

// socket.on('checkingConnection',(data)=>{
//     console.log(data);
// });

// socket.on('save_prod', (data) => {
//     console.log(data.message);
//     store.state.show_notif.show_ = true
//     store.state.show_notif.successType_ = true
//     store.state.show_notif.message_ = data.message
// })
// socket.on('all_cat', (data) => {
//     store.state.my_cat.data = data.reponse_
//     store.state.my_cat.count = data.count;
// })

// socket.on('update_produit', (data) => {
//     console.log('update',data.message);
//     store.state.show_notif.show_ = true
//     store.state.show_notif.successType_ = true
//     // store.state.show_notif.message_ = data.message
// })


// CLIENT NON CONNECTÉ
// socket.on('_all_fourn_', (data) => {
//     store.state.fournisseur = data.reponse
//     console.log(data.reponse);
// })


socket.on('getFournList',(data) => { 
    console.log(data);
    
})




export default socket