<template>
<div class=" w-full h-full fixed top-0 left-0 my_bg2 flex items-center justify-center ">
    <div class="bg-white   rounded-md w-[60vw] overflow-y-auto h-[70vh]  px-6 py-5">
        <div class="flex flex-col">
            <u class=" text-2xl text-black font-semibold sticky top-0 bg-white">Centres d'Intérêt</u>
            <span class="  text-stone-400 mt-3  sticky top-0 bg-white">Sélectionnez vos centres d'intérêt pour personnaliser votre expérience de magasinage et découvrir des produits qui vous passionneront vraiment.</span>
        </div>
        <div v-if=" listProd==null" class="div mt-2 grid grid-cols-4">
            <div v-for="item,i in 12" :key="i" class="flex">
                <span class=" bg-stone-200 py-5 teptep_ rounded-lg flex items-center flex-col mt-8 border w-full mx-2 px-2 font-semibold ">{{ item.cat_label }}</span>
            </div>
        </div>
        <div v-else   class="div mt-2 grid grid-cols-4">
            <div @click="selectIt(item)" v-for="item,i in listProd" :key="i" class="flex">
                <span :class="selectedItem.includes(item.cat_id )?' relative  0 border-black text-black bg-green-300  ':' text-stone-500 border-stone-400 '" class=" py-2 rounded-lg flex items-center flex-col mt-8 border w-full mx-2 px-2 font-semibold ">{{ item.cat_label }}</span>
                <div v-if="selectedItem.includes(item.cat_id )" class=" bg-white  ">
                    <svg class=" fill-current w-5 absolute mt-6 -ml-5" viewBox="0 0 24 24">
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full justify-end mt-10">
            <button @click="validTendance()" :class="this.selectedItem.length>0?' opacity-100':'opacity-25 '" class="flex ml-7 group rounded-md   ">
                <div v-if="load==false" class="flex border rounded-md   px-7 py-1 border-black flex-row">
                    <span class=" ml-1 mr-2 text-stone-700"> Suivant</span>
                    <svg class="ml-2 text-stone-700 w-5 fill-current" viewBox="0 0 24 24">
                        <path d="M16 18h2V6h-2M6 18l8.5-6L6 6v12z" /></svg>
                </div>
                <div v-else class="flex border rounded-md   px-7 py-1 border-black flex-row">
                    <span class=" ml-1 mr-2 text-stone-700"> Patientez</span>
                    <svg class="ml-2 text-stone-700 w-5 fill-current" viewBox="0 0 24 24">
                        <path d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10A10 10 0 0 0 12 2m4.2 14.2L11 13V7h1.5v5.2l4.5 2.7-.8 1.3z" /></svg>
                </div>
            </button>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            listProd: null,
            selectedItem: [],
            load: false,
        }
    },
    props: {
        option: {}
    },
    methods: {
        validTendance() {
            this.load = true
            var req = this.$http.post('api/tendance/' + this.$store.state.client_.client_id, this.selectedItem)
            req.then((rep) => {
                this.$store.state.show_notif.show_ = true;
                this.$store.state.show_notif.message_ = rep.data.message
                this.$store.state.show_notif.successType_ = rep.data.status;
                this.load = false
                this.$store.state.showTendance = false;
            })
        },
        selectIt(item) {
            if (this.selectedItem.includes(item.cat_id)) {
                var indexToRemove = this.selectedItem.indexOf(item.cat_id);
                if (indexToRemove !== -1) {
                    this.selectedItem.splice(indexToRemove, 1);
                }
            } else {
                this.selectedItem.push(item.cat_id)
            }
        }
    },
    mounted() {
        this.$http.get('api/cat_sous_cat').then((rep) => {
            this.listProd = rep.data.data
        })
    }
}
</script>

<style>
    
    </style>
