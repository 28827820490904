<template>
<div class=" h-full duration-500 fixed left-0 w-full overflow-y-auto  " :class="this.$store.state.theme_dark?'bg-gray ':''">
    <div class="flex h-[100vh] ">
        <div class="absolute  left-0 duration-500 flex w-full h-full flex-col overflow-y-auto sm:overflow-hidden" style=" width:100%;height:92%" :class="this.$store.state.theme_dark?'bg-gray ':''">
            <div class=" absolute  bg-black z-20 rounded-full group right-4" title="Fermer " @click="back()">
                <svg class=" w-9" viewBox="0 0 24 24">
                    <path class=" fill-current text-green-700  duration-300 group-hover:text-white" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>
            </div>
            <div :class="isFull?' top-0 ':'mt-9  '" class=" duration-300 z-50 fixed left-0 my_bg2  rounded-r-lg sm:rounded-none flex-col flex  sm:left-1 overflow-auto">
                <div v-for="item, i in $store.state.singleProd_.images" @click="select_this(i)" class=" block mx-1 sm:mx-0 cursor-pointer mt-5 " :key="i">
                    <div class=" h-11 w-11 teptep2_ border-2  " :class="indexImg==i?' border-green-400':' border-gray-500 sm:border-stone-400 opacity-50 sm:opacity-40'">
                        <div v-bind:style="`background-image:url(${set_img_($store.state.singleProd_.images,i)})`" class=" z-20 w-11 h-11 bg-cover  duration-300 block bg-center">
                        </div>
                    </div>
                </div>
            </div>
            <div :class="isFull?' z-40 justify-start fixed top-0 pt-3 bg-black left-0  sm:justify-center ':'mt-2'" class=" sm:mt-0 flex-col sm:flex-row h-full flex w-full   ">
                <div :class="isFull?' w-full ':' w-full sm:w-2/3'" class=" flex flex-col sm:flex-row-reverse sm:justify-center h-full ">
                    <div :class="isFull?' sm:justify-center ':' sm:p-6 sm:pl-8 justify-start'" class="flex flex-row h-full w-full">
                        <div :class=" isFull?'':'teptep2_'" class=" flex   h-full w-full sm:-mt-3 justify-center items-center duration-300">
                            <div @click="setStateImg()" v-bind:style="`background-image:url(${set_img2_($store.state.singleProd_.images)})`" :class="isFull?'bg-contain bg-no-repeat ':' bg-cover'" class=" w-full h-full duration-300 flex bg-center justify-end">
                                <div @click="isFull==true?isFull=false:''" :class="isFull?'opacity-0':' opacity-100 flex right-3 bottom-10 sm:right-auto '" class=" absolute border border-stone-400 my_bg2">
                                    <div class="mr-2 flex flex-row items-center">
                                        <svg v-if="!isFull" class=" fill-current text-white w-7" viewBox="0 0 24 24">
                                            <path d="M5 5h5v2H7v3H5V5m9 0h5v5h-2V7h-3V5m3 9h2v5h-5v-2h3v-3m-7 3v2H5v-5h2v3h3z" /></svg>
                                        <svg v-else class=" fill-current text-white w-7" viewBox="0 0 24 24">
                                            <path d="M14 14h5v2h-3v3h-2v-5m-9 0h5v5H8v-3H5v-2m3-9h2v5H5V8h3V5m11 3v2h-5V5h2v3h3z" /></svg>
                                        <span v-text="isFull?'Quitter':'Voire' " :title="isFull?'Quiter la pleine écran':'Voire en pleine écran' " class=" mr-1 text-xs text-white"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div @click="isFull==true?isFull=false:''" :class="isFull?'flex right-3 mt-8':'hidden right-3 bottom-10 sm:right-auto '" class=" absolute  border border-stone-400 my_bg2">
                            <div class="mr-2 flex flex-row items-center">
                                <svg v-if="!isFull" class=" fill-current text-white w-7" viewBox="0 0 24 24">
                                    <path d="M5 5h5v2H7v3H5V5m9 0h5v5h-2V7h-3V5m3 9h2v5h-5v-2h3v-3m-7 3v2H5v-5h2v3h3z" /></svg>
                                <svg v-else class=" fill-current text-white w-7" viewBox="0 0 24 24">
                                    <path d="M14 14h5v2h-3v3h-2v-5m-9 0h5v5H8v-3H5v-2m3-9h2v5H5V8h3V5m11 3v2h-5V5h2v3h3z" /></svg>
                                <span v-text="isFull?'Quitter':'Voire' " :title="isFull?'Quiter la pleine écran':'Voire en pleine écran' " class=" mr-1 text-xs text-white"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isFull" :class="showDetail?'opacity-100':' opacity-0 '" class="absolute duration-700 bottom-0   w-full flex justify-center ">
                    <div class="border border-gray-500 duration-500  flex w-full  sm:w-4/5 my_bg2 py-5 flex-row">
                        <div class="flex px-4 flex-row justify-start w-full">
                            <div class=" w-3/4">
                                <div class="flex flex-row">
                                    <span class=" block mt-5 mr-3 bg-img-grad w-2 h-2"></span>
                                    <span class=" text-white text-3xl font-bold" v-text="$store.state.singleProd_.prod_label"></span>
                                </div>
                                <div class="flex flex-row w-full">
                                    <div class="flex w-full">
                                        <span class=" text-gray-400 text-base " v-text="$store.state.singleProd_.prod_description"></span>
                                    </div>
                                </div>
                                <div class=" mt-2">
                                    <span class="py-1 rounded-lg font-bold text-xl text-white" v-text="$store.state.singleProd_.prod_prix+' Ar'"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isFull" class="  w-full sm:w-1/2 overflow-y-auto  right-1 bg-stone-900 px-5 ">
                    <descriptions></descriptions>
                    <produitSim></produitSim>
                    <listeProd></listeProd>
                   
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import descriptions from '../../components/myproduct/description_.vue'
import produitSim from '../../components/myproduct/produit_similaire_.vue'
import listeProd from '../../components/myproduct/liste_produi_mypro_.vue'
export default {
    data() {
        return {
            indexImg: 0,
            isFull: false,
            load_img: true,
            showDetail: true,
        };
    },
    components: {
        descriptions,
        produitSim,
        listeProd
    },
    methods: {  
        setStateImg() {
            if (this.isFull == true) {
                this.showDetail == true ? this.showDetail = false : this.showDetail = true;
            } else {
                this.isFull = true
                setTimeout(() => {
                    this.showDetail = false;
                }, 500);
            }

        },
        back() {
            // this.$store.state.loaded=false;
            // console.log( this.$store.state.loaded);
            this.$router.go(-1)
        }, 
        set_img2_(val) {
            try {
                return require(`./../../img/` + val[this.indexImg].img_big)
            } catch (e) {
                console.log(e); return ''
            }
        }, 
        select_this(i) {
            this.load_img = true
            this.indexImg = i
        },
        set_img_(val, i) {
            try {

                return require(`./../../img/compressed/` + val[i].img_midle)
            } catch (e) {
                console.log(e); return ''
            }
        }, 
    },
    created() {
        for (let i = 0; i < this.$store.state.listeProd_[0].length; i++) {
            const element = this.$store.state.listeProd_[0][i];
            if (element.prod_id == this.$route.params.id) {
                this.$store.state.singleProd_ = element
                this.$cookies.set('ito_pro_', element);
            }
        }
        if (!this.$store.state.singleProd_.prod_id) {
            if (this.$cookies.get('ito_pro_').prod_id == this.$route.params.id) {
                this.$store.state.singleProd_ = this.$cookies.get('ito_pro_');
            }
        }
    },
    mounted() {
        console.log(this.$store.state.singleProd_);
        // this.$cookies.set('ito_pro_','bonjour banay') 
    }
}
</script>

<style scoped>
img {
    position: relative;
    object-fit: cover;
    transition: .7s;
}
</style>
