<template>
<div class="  w-full h-[85%] px-3 flex-col overflow-hidden duration-1000">
    <div class=" flex h-3/5 w-full ">
        <div v-if="check_bg_fourn()==false" class=" bg-black w-full h-full"></div>
        <div v-else class=" rounded-b-xl flex teptep2_ w-full h-full">
            <div v-bind:style="`background-image:url(${set_img_()})`" class=" rounded-b-xl z-20 w-full h-full bg-cover  duration-300 flex bg-center justify-end">
                <div @click="type_='bg'" class="bg-fixed delay-500 mx-3">
                    <input class="hidden" accept="image/*" @change="change_files2()" type="file" name="images" id="fourn_profil_bg">
                    <div class="flex mr-0 ">
                        <label for="fourn_profil_bg" class="">
                            <div class="  my_bg2 mt-3 p-3 rounded-full border-white border-2 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" class=" w-5 h-5" viewBox="0 0 24 24" fill="#fff ">
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z" /></svg>
                                <div class="relative flex">
                                    <div class="flex z-auto">
                                        <div class=" w-full h-3/4 absolute top-14 teptep_ bg-stone-700  z-0 "></div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div :class="isTop_?' w-full bg-stone-900':' '" class="  z-30 sticky duration-700 top-0 px-4 flex w-full justify-between -mt-10">
        <div class=" flex flex-row items-center">
            <div class=" flex w-40 h-40  rounded-full border-8 border-stone-900 ">
                <div v-if="check_img_fourn()==true" v-bind:style="`background-image:url(${load_img2_()})`" class="flex w-40 h-40  bg-cover duration-300  hover:scale-125 z-20 flex-row justify-end"></div>
                <div v-else class=" w-40 h-40 duration-1000 bg-black rounded-full">
                    <svg class=" fill-current text-white w-full" viewBox="0 0 24 24">
                        <path d="M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10c0-5.53-4.5-10-10-10z" /></svg>

                </div>
            </div>
            <div :class="isTop_?'ml-1':'ml-4'" class=" flex flex-col ">
                <div class=" flex px-4 my_bg rounded-md border border-white">
                    <div class="flex  flex-row">
                        <span :class="isTop_?'text-base font-semibold':' text-xl sm:text-4xl'" class=" text-white" v-text="this.fournisseur.name"> </span>
                    </div>
                </div>
                <div :class="isTop_?' hidden':'flex'" class="  flex-row">
                    <svg class="w-4 ml-2 mr-1 fill-current text-gray-600" viewBox="0 0 24 24">
                        <path d="M12 11.5A2.5 2.5 0 0 1 9.5 9 2.5 2.5 0 0 1 12 6.5 2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7z" /></svg>
                    <span class=" text-xs sm:text-base text-gray-500" v-text="this.fournisseur.ville"></span>
                </div>
                <div :class="isTop_?' hidden':'flex'" class="  flex-row">
                    <svg class="w-4 ml-2 mr-1 fill-current text-gray-600" viewBox="0 0 24 24">
                        <path d="M12 18H6v-4h6m9 0v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6m0-10H4v2h16V4z"></path>
                    </svg>
                    <span class=" text-xs sm:text-base text-gray-500" v-text="this.fournisseur.localisation"></span>
                </div>
                <div class="flex flex-row">
                    <span :class="isTop_?' text-xs':'text-xl'" class=" text-green-500" v-text="this.fournisseur.tel"></span>
                </div>
            </div>
        </div>
        <div :class="isTop_?' mt-3':' '" class=" absolute sm:static flex -mt-3 flex-col">
            <div class="flex flex-row mt-3 my_bg justify-center rounded-lg border border-white fill-current text-white">
                <svg class="mx-1 w-5" viewBox="0 0 24 24">
                    <path d="M12 2.04c-5.5 0-10 4.49-10 10.02 0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89 1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02z" /></svg>
                <svg class="mx-1 w-5" viewBox="0 0 24 24">
                    <path d="M20 18h-2V9.25L12 13 6 9.25V18H4V6h1.2l6.8 4.25L18.8 6H20m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" /></svg>
                <svg class="mx-1 w-5" viewBox="0 0 24 24">
                    <path d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91 0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21 5.46 0 9.91-4.45 9.91-9.91 0-2.65-1.03-5.14-2.9-7.01A9.816 9.816 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.225 8.225 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23-1.48 0-2.93-.39-4.19-1.15l-.3-.17-3.12.82.83-3.04-.2-.32a8.188 8.188 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31-.22.25-.87.86-.87 2.07 0 1.22.89 2.39 1 2.56.14.17 1.76 2.67 4.25 3.73.59.27 1.05.42 1.41.53.59.19 1.13.16 1.56.1.48-.07 1.46-.6 1.67-1.18.21-.58.21-1.07.15-1.18-.07-.1-.23-.16-.48-.27-.25-.14-1.47-.74-1.69-.82-.23-.08-.37-.12-.56.12-.16.25-.64.81-.78.97-.15.17-.29.19-.53.07-.26-.13-1.06-.39-2-1.23-.74-.66-1.23-1.47-1.38-1.72-.12-.24-.01-.39.11-.5.11-.11.27-.29.37-.44.13-.14.17-.25.25-.41.08-.17.04-.31-.02-.43-.06-.11-.56-1.35-.77-1.84-.2-.48-.4-.42-.56-.43-.14 0-.3-.01-.47-.01z" /></svg>
                <svg class="mx-1 w-5" viewBox="0 0 24 24">
                    <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z" /></svg>
                <svg class="mx-1 w-5" viewBox="0 0 24 24">
                    <path d="m10 15 5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z" /></svg>
            </div>
        </div>
    </div>
    <div class="flex flex-row justify-between  pt-5 w-full ">
        <div class=" flex flex-col-reverse text-stone-400   ">
            <span class=" break-before-avoid" v-text=" get_date()"></span>

            <div @click="showForm_('perso')" class=" cursor-pointer flex items-center  w-full flex-row">
                <div class="flex bg-green-700 px-4 rounded-md">
                    <span class=" text-white" v-text="this.fournisseur.nbrLike"> </span>
                    <svg class="ml-3 fill-current text-white w-5" viewBox="0 0 24 24">
                        <path d="M16 3.23C16.71 2.41 17.61 2 18.7 2c.91 0 1.67.33 2.3 1 .63.67.96 1.43 1 2.3 0 .7-.33 1.51-1 2.46s-1.32 1.74-1.97 2.39c-.65.64-1.66 1.59-3.03 2.85-1.39-1.26-2.41-2.21-3.06-2.85s-1.31-1.44-1.97-2.39C10.31 6.81 10 6 10 5.3c0-.91.32-1.67.97-2.3.65-.63 1.43-.96 2.34-1 1.07 0 1.96.41 2.69 1.23M22 19v1l-8 2.5-7-1.94V22H1V11h7.97l6.16 2.3A2.89 2.89 0 0 1 17 16h2c1.66 0 3 1.34 3 3M5 20v-7H3v7h2m14.9-1.43c-.16-.33-.51-.57-.9-.57h-5.35c-.54 0-1.07-.08-1.58-.25l-2.38-.79.63-1.9 2.38.79c.3.1 2.3.15 2.3.15 0-.37-.23-.7-.57-.83L8.61 13H7v5.5l6.97 1.91 5.93-1.84z" /></svg>
                </div>
            </div>
        </div>
        <div class="flex  pr-3 items-center justify-end">
            <span class=" text-2xl text-stone-500 font-bold"> 5 </span>
            <button class=" ml-5 p-1 bg-stone-700 rounded-md flex px-3 justify-center flex-row items-center ">
                <span class=" text-stone-200 font-bold text-sm"> S'abonner</span>
                <svg class=" text-stone-200 fill-current w-6" viewBox="0 0 24 24">
                    <path d="M16 17H7v-6.5C7 8 9 6 11.5 6S16 8 16 10.5m2 5.5v-5.5c0-3.07-2.14-5.64-5-6.32V3.5A1.5 1.5 0 0 0 11.5 2 1.5 1.5 0 0 0 10 3.5v.68c-2.87.68-5 3.25-5 6.32V16l-2 2v1h17v-1m-8.5 4a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2z" /></svg>
            </button>
        </div>
    </div>
</div>
</template>

<script>
export default {

    data() {
        return {
            isTop_: false,
            showInput: {
                boutique: false
            },
            mydleImg: this.$store.state.fournisseur.fourn_img_log_middle,
            inputs: [{
                model_: '',
                ico_: '<path d="M12 18H6v-4h6m9 0v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6m0-10H4v2h16V4z" />',
                plh_: "Nom du boutique",
                type_: "text"
            }, {
                model_: '',
                ico_: '<path d="m20 8-8 5-8-5V6l8 5 8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" />',
                plh_: 'adresse email du boutique',
                type_: 'email'
            }, {
                model_: '',
                ico_: '<path d="M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25 1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1V20a1 1 0 0 1-1 1A17 17 0 0 1 3 4a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />',
                plh_: 'Numéro de téléphone',
                type_: 'text'
            }],
            fournisseur: {
                name: '',
                ville: '',
                email: '',
                nbrLike: '',
                localisation: '',
                tel: '',
            }
        }
    },
    methods: {
        load_img2_() {
            try {
                for (let i = 0; i < this.$store.state.listFourn.length; i++) {
                    const element = this.$store.state.listFourn[i];
                    if (element.fourn_id == this.$route.params.id) {
                        return require(`.././../../img/compressed/${element.fourn_img_log_middle}`);
                    }
                }
            } catch (e) {
                console.log(e); return '';
            }
        },
        set_img_() {
            try {
                return require(`.././../../img/compressed/${this.$store.state.listFourn[this.$route.params.id].fourn_img_bg}`);
            } catch (e) {
                console.log(e); return '';
            }
        },
        change_files() {
            var img_val = document.getElementById('fourn_profil')
            this.$store.state.imgTemp[0] = URL.createObjectURL(img_val.files[0])
            this.$store.state.imgTemp[1] = img_val.files[0];
            this.$store.state.imgTemp[2] = 'logo';
        },
        change_files2() {
            var img_val = document.getElementById('fourn_profil_bg')
            this.$store.state.imgTemp[0] = URL.createObjectURL(img_val.files[0])
            this.$store.state.imgTemp[1] = img_val.files[0];
            this.$store.state.imgTemp[2] = 'bg';

        },
        check_bg_fourn() {
            try {
                for (let i = 0; i < this.$store.state.listFourn.length; i++) {
                    const element = this.$store.state.listFourn[i];
                    if (element.fourn_id == this.$route.params.id) {
                        require(`.././../../img/compressed/${element.fourn_img_bg}`)
                        if (element.fourn_img_bg == null) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            } catch (e) {
                return false;
            }
        },
        check_img_fourn() {
            try {
                for (let i = 0; i < this.$store.state.listFourn.length; i++) {
                    const element = this.$store.state.listFourn[i];
                    if (element.fourn_id == this.$route.params.id) {
                        this.get_fourn_property(element.fourn_name, element.fourn_email, element.ville_id, 30, element.localisation_, element.fourn_tel)
                        require(`.././../../img/compressed/${element.fourn_img_log_middle}`)
                        if (element.fourn_img_log_middle == null) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            } catch (e) {
                return false;
            }
        },
        get_fourn_property(name, email, ville, nbr, localisation, tel) {
            this.fournisseur.name = name;
            this.fournisseur.ville = ville;
            this.fournisseur.email = email;
            this.fournisseur.nbrLike = nbr;
            this.fournisseur.localisation = localisation;
            this.fournisseur.tel = tel;

        },
        get_date() {
            try {
                for (let i = 0; i < this.$store.state.listFourn.length; i++) {
                    const element = this.$store.state.listFourn[i];
                    if (element.fourn_id == this.$route.params.id) {
                        // var date = new Date(element.fourn_date_enreg).toLocaleDateString('fr')
                        var date = new Date(element.fourn_date_enreg).toDateString()
                        return 'Membre depuis ' + date
                    }
                }
            } catch (e) {
                console.log(e);
            }

        },
    },
    mounted() {}
}
</script>

<style>

</style>
