<template>
<div class=" text-white flex flex-col duration-300 font-semibold w-full justify-end z-40 bg-gray ">
    <div class=" flex-col w-full flex py-3 px-3">
        <div :class="!is_Top?' justify-center':'  '" class="flex flex-row mt-3 px-4  w-full ">
            <div v-if="$store.state.sous_categorie.length!=0" @click="get_all()" :class="!is_Top?this.$route.query.raiso=='all'?'  px-5 border border-green-400 rounded-xl mx-2':'  px-5 border border-stone-400 rounded-xl mx-2':' px-2 mx-2 '" class="">
                <span :class="this.$route.query.raiso=='all'?' text-green-500 ':''">{{'Tous'}}</span>
            </div>
            <div v-if="$store.state.sous_categorie.length==0" class=" w-full flex flex-row justify-center ">
                <div class=" mx-2 flex" v-for=" i in 4" :key="i">
                    <div class="flex w-36 justify-center border border-stone-500 py-2 px-3 rounded-full">
                        <span class=" h-3 w-28 teptep_ bg-stone-500 block"></span>
                    </div>
                </div>
            </div>
            <div @click="get_id(item)" v-for="item,i in  $store.state.sous_categorie.data " :key="i" :class="!is_Top?this.$route.query.raiso==item.sous_cat_label?' px-5 border border-green-400 rounded-xl mx-2':'border border-stone-400 mx-2 rounded-xl px-5':' px-2 mx-2'" class=" ">
                <span :class="this.$route.query.raiso==item.sous_cat_label?' text-green-500 ':''">{{ item.sous_cat_label }}</span>
            </div>
        </div>

        <div class="flex flex-col px-3">
            <div class="flex w-full border-t border-stone-700  my-4"></div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        is_Top: {}
    },
    data() {
        return {

        }
    },
    methods: {
        get_id(val) {
            this.$router.replace({
                    name: 'thisFourn',
                    query: {
                        name: this.$route.query.name,
                        raiso: val.sous_cat_label
                    },
                    params: {
                        id: this.$route.params.id
                    }
                } 
            ) 
        },
        get_all() {
            this.$router.replace({
                    name: 'thisFourn',
                    query: {
                        name: this.$route.query.name,
                        raiso: 'all'
                    },
                    params: {
                        id: this.$route.params.id
                    }
                } 
            )
            this.$store.commit('enreg_', {
                methode: 'get',
                url: 'api/produit/' + this.$store.state.fournisseur.fourn_id,
                data: {},
            })
            // console.log(this.$store.state.fournisseur.fourn_id);
        }
    },
    mounted() {
        // console.log( this.$route);
    },
    created() {},
    beforeCreate() {

    },
}
</script>

<style>

</style>
