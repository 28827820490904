<template>
<div class="flex flex-col justify-start w-full">
    <div class="flex flex-row mt-7">
        <svg v-if="set_img_fourn_($store.state.singleProd_.fourn_img_log_middle)==''" class=" w-9 h-9 text-white fill-current" viewBox="0 0 24 24">
            <path d="M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10c0-5.53-4.5-10-10-10z" /></svg>
        <div v-else :style="`background-image:url(${set_img_fourn_($store.state.singleProd_.fourn_img_log_middle)})`" class="h-12 w-12 rounded-full bg-cover flex justify-center items-center"></div>
        <div class="flex flex-col ml-2">
            <span class="  text-green-500 font-bold" v-text="$store.state.singleProd_.fourn_name"></span>
            <span class="  text-stone-500 text-xs font-bold" v-text="$store.state.singleProd_.fourn_email"></span>
        </div>
    </div>
    <div class="flex flex-col">
        <div class="flex w-full  justify-between flex-row">
            <div class=" flex flex-row items-center">
                <span class=" block mt-5 mr-3 bg-img-grad w-2 h-2"></span>
                <span class=" text-white text-xl mt-3 font-bold" v-text="$store.state.singleProd_.prod_label"></span>
            </div>
        </div>
    </div>
    <div class="flex flex-col w-full mt-3">
        <div class="flex flex-row">
            <div class="flex flex-row justify-between w-full ">
                <div class="flex flex-col">
                    <span class=" text-gray-200 text-base " v-text="$store.state.singleProd_.prod_description"></span>
                    <div class="flex flex-row">
                        <div class="flex flex-row">
                            <svg class=" text-gray-400  w-4 h-4 fill-current mr-2" viewBox="0 0 24 24">
                                <path d="M12 20a8 8 0 0 1-8-8 8 8 0 0 1 8-8 8 8 0 0 1 8 8 8 8 0 0 1-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10A10 10 0 0 0 12 2m4.24 5.76A5.95 5.95 0 0 0 12 6v6l-4.24 4.24a6 6 0 0 0 8.48 0 5.981 5.981 0 0 0 0-8.48z" /></svg>
                            <span class=" text-gray-400 text-xs " v-text="$store.state.singleProd_.prod_date_enreg"></span>

                        </div>
                        <div class="flex flex-row ml-6">
                            <svg class=" text-gray-400  w-4 h-4 fill-current mr-2" viewBox="0 0 24 24">
                                <path d="M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7m-4.5 8H10V5h4v5h2.5L12 14.5z" /></svg>
                            <span class=" text-gray-400 text-xs " v-text="$store.state.singleProd_.ville_id"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="emotion_" class="mt-7 flex flex-row justify-between items-center  w-full">

        <div class="flex bg-stone-700 px-5">
            <svg class=" w-5 fill-current text-white " viewBox="0 0 24 24">
                <path d="M16 17H7v-6.5C7 8 9 6 11.5 6S16 8 16 10.5m2 5.5v-5.5c0-3.07-2.14-5.64-5-6.32V3.5A1.5 1.5 0 0 0 11.5 2 1.5 1.5 0 0 0 10 3.5v.68c-2.87.68-5 3.25-5 6.32V16l-2 2v1h17v-1m-8.5 4a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2z" /></svg>
            <span class=" text-white"> S'abonner</span>
        </div>
        <div class="flex flex-row">
            <div title="J'aime  " class=" cursor-pointer flex flex-row items-center text-white bg-black px-5 rounded-full py-1 ">
                <svg class=" text-gray-400 w-5 h-5 fill-current mr-2" viewBox="0 0 24 24">
                    <path d="M5 9v12H1V9h4m4 12a2 2 0 0 1-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.06c.27.27.44.64.44 1.05l-.03.32L14.69 8H21a2 2 0 0 1 2 2v2c0 .26-.05.5-.14.73l-3.02 7.05C19.54 20.5 18.83 21 18 21H9m0-2h9.03L21 12v-2h-8.79l1.13-5.32L9 9.03V19z" /></svg>
                <span class=" text-sm">144</span>
            </div>
            <div title="Je n'aime pas" class="ml-3 cursor-pointer flex flex-row items-center text-white bg-black px-5 rounded-full py-1 ">
                <svg class=" transform rotate-180 text-gray-400 w-5 h-5 fill-current mr-2" viewBox="0 0 24 24">
                    <path d="M5 9v12H1V9h4m4 12a2 2 0 0 1-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.06c.27.27.44.64.44 1.05l-.03.32L14.69 8H21a2 2 0 0 1 2 2v2c0 .26-.05.5-.14.73l-3.02 7.05C19.54 20.5 18.83 21 18 21H9m0-2h9.03L21 12v-2h-8.79l1.13-5.32L9 9.03V19z" /></svg>
                <span class=" text-sm">12</span>
            </div>
            <div title="Evoyer un message" class="ml-3 cursor-pointer flex flex-row items-center text-white bg-black px-5 rounded-full py-1 ">
                <svg class=" text-gray-400 w-5 h-5 fill-current mr-2" viewBox="0 0 24 24">
                    <path d="M17 12V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v14l4-4h10a1 1 0 0 0 1-1m4-6h-2v9H6v2a1 1 0 0 0 1 1h11l4 4V7a1 1 0 0 0-1-1z" /></svg>

            </div>
        </div>
    </div>
    <div class="flex items-center mt-3 flex-row">
        <span class=" font-bold text-green-500" v-text="'Prix:'"></span>
        <span class=" ml-2 py-1 rounded-lg font-bold text-xl text-green-500" v-text="$store.state.singleProd_.prod_prix+' Ar'"></span>
    </div>
</div>
</template>

<script>

export default {
    props:{
        options:{}
    },
    data() {
        return { 
        };
    },
    components: {},
    methods: { 
        set_img_fourn_(val) { 
            try {
                return require(`../../../../img/compressed/${val}`)
            } catch (e) {
                console.log(e); return ''
            }
        }, 
    },
    created() { 
    },
    mounted() { 
        // var k= document.getElementById('emotion_')
        
    }
}
</script>

<style>
</style>
