<template>
    <div class="flex flex-row items-center">
     <div class="">
         <div class=" z-0 flex flex-row items-center  relative scale-[1.04] ">
             <span class=" sm:text-xl font-bold rounded-l-md pl-1 text-gray-500">An</span>
             <span class=" sm:text-xl text-white  rounded-r-full">Da</span>
         </div>
         <span class=" z-10 absolute -mt-8 border_ ml-11  bg-img-grad rounded-full px-1 text-white text-xs font-bold"> Tsena </span>
     </div> 
 </div> 
     </template>
     
     <script> 
     export default {
         components:{ },
         data() {
             return { 
             }
         },
         methods:{ 
         
         }
     
     }
     </script>
     
     <style>
     </style>
     