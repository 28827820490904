<template>
<div class="flex " style="">
    <div v-if=" this.$store.state.liste_prod_fourn[0]==null" class="w-fullduration-700 max-h-full grid-cols-1 grid sm:grid-cols-2 sm:mb-14  xl:grid-cols-3 overflow-y-auto">
        <div v-for="k in  6" :key="k" class=" border opacity-100 border-gray-800  flex flex-col items-center justify-center py-3  duration-300 my-7 mx-8   ">
            <div class="teptep_  item-center block h-40 w-40 border border-black relative  bg-stone-600 ">
            </div>
            <div class="flex px-1 text-left w-full flex-col">
                <span class="teptep_  bg-stone-600 w-28 h-6 mb-1 "></span>
                <span class="teptep_  bg-stone-600 w-full h-3 mb-1 "></span>
                <span class="teptep_  bg-stone-600 w-full h-3 mb-1 "></span>
                <div class="flex text-left mt-3 flex-col">
                    <span class="teptep_  bg-stone-600 w-14 h-3 mb-1 "></span>
                    <span class="teptep_  bg-stone-600 w-14 h-5 mb-1 "></span>
                </div>
                <div>
                    <span class="teptep_  bg-stone-600 w-28 h-6 mb-1 "></span>
                </div>
            </div>
        </div>
    </div>
    <!-- <span  class=" text-2xl opacity-25 border-2 border-red-500 text-red-500 font-bold">Tsy mahazo alalana</span> -->
            
    <div v-else class="w-full  duration-700 grid  grid-cols-1 sm:grid-cols-3  lg:grid-cols-4   2xl:grid-cols-6 sm:mb-3  overflow-y-auto">

        <router-link  :style="item.prod_disp==false?'display:none':''"  v-for="item,i in  this.$store.state.liste_prod_fourn[0]" :key="i" :to="{name:'myProduct',query:{name:item.prod_label},params:{id:item.prod_id}}" :class="item.prod_disp?'sm:border opacity-100 sm:border-gray-800 hover:border-green-500 ':'border  hover:border-white border-gray-800  opacity-30'" class=" h-96 sm:h-72 flex group flex-col items-center justify-center pb-2 sm:pb-1  duration-300 my-7 mx-6 2xl:mx-5  ">
            <div class="flex   item-center h-full sm:h-40 w-full sm:border sm:border-black relative teptep2_   ">
                 <div v-bind:style="`background-image:url(${set_img_(item)})`" class=" w-full h-full bg-cover hover:scale-150 duration-300 flex justify-center items-center">
                </div>
            </div>
            <div class="flex px-1 text-left w-full flex-col">
                <span class=" text-white font-bold" v-text="item.prod_label"></span>
                <span class=" text-gray-400 text-sm" v-text="cut_descript(item.prod_description)"></span>
                <div class=" mt-1 flex flex-row items-center justify-between">
                    <div class="flex text-left mt-3 flex-col">
                        <span class=" text-gray-400 text-xs" v-text="'à partir de'"></span>
                        <span class=" text-white font-bold" v-text="item.prod_prix+' Ar'"></span>
                    </div>
                    <div class=" flex flex-col-reverse relative mt-4">
                        <div class="flex flex-row items-center relative mt-1">
                            <div class="hidden">
                                <div title="Ajouter au pannier" class=" flex p-1 mr-3 rounded-lg text-white  bg-stone-800">
                                    <svg class=" fill-current text-green-400 w-6" viewBox="0 0 24 24">
                                        <path d="M11 9h2V6h3V4h-3V1h-2v3H8v2h3M7 18a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2m10 0a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2m-9.83-3.25.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4h-.01l-1.1 2-2.76 5H8.53l-.13-.27L6.16 6l-.95-2-.94-2H1v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96a2 2 0 0 0 2 2h12v-2H7.42c-.13 0-.25-.11-.25-.25z" /></svg>
                                </div>
                            </div>
                            <div class="flex">
                                <div title="Like" class=" flex p-1 ml-3 rounded-lg  text-white bg-stone-800">
                                    <svg class=" fill-current text-green-400 w-6" viewBox="0 0 24 24">
                                        <path d="m12.67 20.74-.67.61-1.45-1.32C5.4 15.36 2 12.27 2 8.5 2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.41 22 8.5c0 1.43-.5 2.76-1.38 4.11-.62-.3-1.31-.5-2.03-.57C19.5 10.8 20 9.65 20 8.5c0-2-1.5-3.5-3.5-3.5-1.54 0-3.04 1-3.57 2.36h-1.86C10.54 6 9.04 5 7.5 5 5.5 5 4 6.5 4 8.5c0 2.89 3.14 5.74 7.89 10.05l.11.1.04-.04c.08.76.3 1.48.63 2.13M17 14v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z" /></svg>
                                </div>
                            </div>
                        </div>
                        <span class=" text-xs text-gray-500  " v-text="item.prod_date_enreg"></span>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            isLoad: true, 
        }
    },
    components: {},
    methods: {
        set_img_(val) { 
                return require(`../../../../img/compressed/${val.images[0].img_midle}`)
        },
        cut_descript(val){
            if(val.length>35){
                return val.substring(0,33)+'...'
            }
            return val
        }
    }, 
}
</script>

<style scoped>
#my_{
    /* background-image: url('.././../../img/anda_img_1677495665869.jpg'); */
    /* width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat; */
}

img:hover {
    transform: scale(1.35);
    cursor: pointer;
}

img {
    position: relative;
    object-fit: cover;
    transition: .3s;
}



@keyframes skeleton {
    to {
        opacity: 0.6;
    }
}
</style>
