<template>
<div class=" flex mt-7 flex-col "> 
    <span class="text-stone-500">Produit similaire</span>
    <div class="w-full h-full border-t border-gray-800  duration-700 grid  grid-cols-1 ">
        <router-link v-for="item,i in  this.$store.state.listeProd_[0]" :key="i" :to="{name:'myProduct',query:{name:item.prod_label},params:{id:item.prod_id}}">
            <div v-if="i<8" :class="item.prod_disp?'sm:border opacity-100 sm:border-gray-800 group hover:border-green-500 ':'border  hover:border-white border-gray-800  opacity-30'" class=" mt-7 flex flex-row items-center justify-center pb-2 sm:pb-1  duration-300    ">

                <div class="flex item-center h-full sm:h-56 w-[20vw] sm:border sm:border-black relative teptep2_   ">
                    <div v-if="item.images[0].img_midle!=undefined" :style="`background-image:url(${set_img_grid_(item.images[0].img_midle)})`" class=" w-full h-full bg-cover scale-100 group-hover:scale-110 hover:scale-[115rem] duration-300 flex justify-center items-center">
                        <!-- <span class=" text-2xl border-2 border-red-500 text-red-500 font-bold">Tsy mahazo alalana</span> -->
                    </div>
                </div>
                <div class="flex ml-4 flex-col h-56"> 
                    <div class="flex px-1 text-left w-full h-full justify-between flex-col">
                        <!-- description du fournisseur -->
                        <div class="flex w-full flex-row cursor-pointer items-center py-2 px-3">
                            <svg v-if="set_img_grid_(item.fourn_img_log_middle)==''" class=" w-9 h-9 text-white fill-current" viewBox="0 0 24 24">
                                <path d="M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10c0-5.53-4.5-10-10-10z" /></svg>
                            <div v-else :style="`background-image:url(${set_img_grid_(item.fourn_img_log_middle)})`" class="h-9 w-9 rounded-full bg-cover flex justify-center items-center">
                            </div>
                            <div class="flex flex-col justify-center">
                                <span class=" font-bold ml-1 text-green-400 text-sm" v-text="item.fourn_name"></span>
                                <span class=" text-xs text-gray-500 ml-1  " v-text="item.prod_date_enreg"></span>
                            </div>
                        </div>

                        <div class="flex w-full flex-col mt-1">
                            <span class=" text-white font-bold" v-text="item.prod_label"></span>
                            <span class=" text-gray-400 pb-1 text-sm" v-text="cut_descript(item.prod_description)"></span>
                        </div>
                        <div class=" mt-1 flex flex-row items-center justify-between">
                            <div class="flex text-left mt-1 flex-col">
                                <span class=" text-gray-400 text-xs" v-text="'à partir de'"></span>
                                <span class=" text-white font-bold" v-text="item.prod_prix+' Ar'"></span>
                            </div>
                            <!-- <div class=" flex flex-col">
                                <div class="flex items-center flex-row">
                                    <svg viewBox="0 0 24 24" class=" text-white fill-current w-6">
                                        <path d="M5 9v12H1V9h4m4 12a2 2 0 0 1-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.06c.27.27.44.64.44 1.05l-.03.32L14.69 8H21a2 2 0 0 1 2 2v2c0 .26-.05.5-.14.73l-3.02 7.05C19.54 20.5 18.83 21 18 21H9m0-2h9.03L21 12v-2h-8.79l1.13-5.32L9 9.03V19z" /></svg>
                                    <span class=" text-green-400 text-xs" v-text="'120'"></span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>

</div>
</template>

<script>
export default {
    methods: {

        set_img_grid_(val) {
            try {
                return require(`./../../img/compressed/${val}`)
            } catch (e) {
                console.log(e); return ''

            }
        },

        cut_descript(val) {
            if (val.length > 100) {
                return val.substring(0, 97) + '...'
            }
            return val
        },
    }
}
</script>
