<template>
<div class="flex flex-col  h-full  w-full ">

    <div v-if="this.$store.state.listeProd_[1]==null" class="w-full h-full duration-700 grid  grid-cols-1  md:grid-cols-2  xl:grid-cols-2  2xl:grid-cols-3 sm:mb-2 overflow-y-auto">
        <div v-for="k in  8" :key="k" class=" h-96 sm:h-72 flex flex-col items-center justify-center pb-2 sm:pb-1  duration-300 my-7 2xl:my-3 mx-7 2xl:mx-5   ">
            <div class="teptep_  item-center block h-40 w-full border border-black relative  bg-stone-600 ">
            </div>
            <div class="flex px-1 text-left w-full flex-col">
                <span class="teptep_  bg-stone-600 w-28 h-4 mt-1 mb-1 "></span>
                <span class="teptep_  bg-stone-600 w-full h-2 mb-1 "></span>
                <span class="teptep_  bg-stone-600 w-full h-2 mb-1 "></span>
                <div class="flex flex-row w-full mt-2 justify-between">
                    <div class="flex text-left  selection: flex-col">
                        <span class="teptep_  bg-stone-600 w-14 h-3 mb-1 "></span>
                        <span class="teptep_  bg-stone-600 w-14 h-5 mb-1 "></span>
                    </div>
                    <div class="flex text-left   flex-col">
                        <span class="teptep_  bg-stone-600 w-24 h-3 mb-1 "></span>
                        <span class="teptep_  bg-stone-600 w-24 h-5 mb-1 "></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="product-containner" @scroll=" checkScrolling()" class=" overflow-y-auto max-h-[91vh] w-full  ">
        <div class="w-full h-full duration-700 grid  grid-cols-1  md:grid-cols-2  xl:grid-cols-2  2xl:grid-cols-3 sm:mb-2 ">
            
                <router-link  v-for="item,i in  this.$store.state.listeProd_[0]" :key="i" :to="{name:'myProduct',query:{name:item.prod_label},params:{id:item.prod_id}}"  class="sm:border opacity-100 sm:border-gray-800 group hover:border-green-500  h-[30rem] sm:h-[25rem] flex flex-col items-center justify-center pb-2 sm:pb-1  duration-300 my-7 2xl:my-3 mx-7 2xl:mx-5   ">
                    <div class="flex w-full flex-row cursor-pointer items-center py-2 px-3">
                        <svg v-if="set_img_(item.fourn_img_log_middle)==''" class=" w-9 h-9 text-white fill-current" viewBox="0 0 24 24">
                            <path d="M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10c0-5.53-4.5-10-10-10z" /></svg>
                        <div v-else :style="`background-image:url(${set_img_(item.fourn_img_log_middle)})`" class="h-9 w-9 rounded-full bg-cover flex justify-center items-center">
                        </div>
                        <div class="flex flex-col justify-center">
                            <span class=" font-bold ml-1 text-green-400 text-sm" v-text="item.fourn_name"></span>
                            <span class=" text-xs text-gray-500 ml-1  " v-text="item.prod_date_enreg"></span>
                        </div>
                    </div>
                    <div class="flex item-center h-full sm:h-56 w-full sm:border sm:border-black relative teptep2_   ">
                        <div v-if="item.images[0].img_midle!=undefined" :style="`background-image:url(${set_img_(item.images[0].img_midle)})`" class=" w-full h-full bg-cover scale-100 group-hover:scale-110 hover:scale-[115rem] duration-300 flex justify-center items-center">
                        </div>
                    </div>
                    <div class="flex px-1 text-left w-full flex-col">
                        <div class="flex w-full flex-col mt-1">
                            <span class=" text-white font-bold" v-text="item.prod_label"></span>
                            <span class=" text-gray-400 pb-1 text-sm" v-text="cut_descript(item.prod_description)"></span>
                        </div>
                        <div class=" mt-1 flex flex-row items-center justify-between">
                            <div class="flex text-left mt-1 flex-col">
                                <span class=" text-gray-400 text-xs" v-text="'à partir de'"></span>
                                <span class=" text-white font-bold" v-text="item.prod_prix+' Ar'"></span>
                            </div>
                            <div class=" flex flex-col">
                                <div class="flex items-center flex-row">
                                    <svg viewBox="0 0 24 24" class=" text-white fill-current w-6">
                                        <path d="M5 9v12H1V9h4m4 12a2 2 0 0 1-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.06c.27.27.44.64.44 1.05l-.03.32L14.69 8H21a2 2 0 0 1 2 2v2c0 .26-.05.5-.14.73l-3.02 7.05C19.54 20.5 18.83 21 18 21H9m0-2h9.03L21 12v-2h-8.79l1.13-5.32L9 9.03V19z" /></svg>
                                    <span class=" text-green-400 text-xs" v-text="'120'"></span>
                                </div>
                            </div>
                        </div>
                    </div> 
                </router-link>

                <div  v-for=" i in  2" :key="i"   class="sm:border opacity-100 sm:border-gray-800  h-[30rem] sm:h-[25rem] flex flex-col items-center justify-center pb-2 sm:pb-1  duration-300 my-7 2xl:my-3 mx-7 2xl:mx-5   ">
                    <div class="flex w-full flex-row cursor-pointer items-center py-2 px-3">
                         <div   class="h-9 w-9 teptep_ bg-stone-500 rounded-full bg-cover flex justify-center items-center">
                        </div>
                        <div class="flex flex-col w-[40%] justify-center">
                            <span class="  w-[50%]  py-1 rounded-md bg-stone-400 "  ></span>
                            <span class=" ml-1 py-1 mt-1 rounded-md bg-stone-400" ></span>
                        </div>
                    </div>
                    <div class="flex item-center h-full sm:h-56 w-full sm:border sm:border-black relative teptep2_   ">
                        <div  class="teptep_ bg-stone-500  w-full h-full bg-cover scale-100 group-hover:scale-110 hover:scale-[115rem] duration-300 flex justify-center items-center">
                        </div>
                    </div>
                    <div class="flex px-1 text-left w-full flex-col">
                        <div class="flex w-full flex-col mt-1">
                            <span class=" w-[40%] rounded-md text-white   py-2 bg-stone-400"  ></span>
                            <span class=" mt-2 pb-1  rounded-md py-2 bg-stone-400 "></span>
                        </div>
                        <div class=" mt-3 flex flex-row items-center justify-between">
                            <div class=" w-20 flex text-left mt-1 flex-col">
                                <span class=" rounded-md py-1 bg-stone-400 "  ></span>
                                <span class=" rounded-md py-2 mt-1 bg-stone-400" ></span>
                            </div>
                            <div class=" flex flex-col">
                                <div class="flex items-center flex-row">
                                    <svg viewBox="0 0 24 24" class=" text-white fill-current w-6">
                                        <path d="M5 9v12H1V9h4m4 12a2 2 0 0 1-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.06c.27.27.44.64.44 1.05l-.03.32L14.69 8H21a2 2 0 0 1 2 2v2c0 .26-.05.5-.14.73l-3.02 7.05C19.54 20.5 18.83 21 18 21H9m0-2h9.03L21 12v-2h-8.79l1.13-5.32L9 9.03V19z" /></svg>
                                    <span class="py-2 bg-stone-400 w-11 rounded-md "></span>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            
             <!-- chagement -->
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        set_img_(val) {
            try {
                return require(`../../../img/compressed/${val}`)
            } catch (e) {
                console.log(e); return ''
            }
        },
        cut_descript(val) {
            if (val.length > 100) {
                return val.substring(0, 97) + '...'
            }
            return val
        },
        async checkScrolling() {
            const containner = document.getElementById('product-containner');
            const isScrollAtBottom = containner.scrollTop + containner.clientHeight >= containner.scrollHeight - 5;

            this.$store.state.scrollPosition = containner.scrollTop;  

            if (isScrollAtBottom) {
                await this.$http.get('api/prodList/' + this.$store.state.indexOfPage).then((resp) => {
                    for (let i = 0; i < resp.data.reponse.length; i++) {
                        const element = resp.data.reponse[i];
                        this.$store.state.listeProd_[0].push(element);
                    }
                   this.$store.state.indexOfPage += 16;
                })
            }
        },

    },
    updated() {

    },
    mounted(){

        const containner = document.getElementById('product-containner');
        containner.scrollTop=this.$store.state.scrollPosition;
    }
}
</script>

<style scoped>
img:hover {
    transform: scale(1.35);
    cursor: pointer;
}

img {
    position: relative;
    object-fit: cover;
    transition: .3s;
}
</style>
