<template>
<div class="w-[35rem] h-full  border-l border-l-gray-800 flex px-3 flex-col py-4">
    <span class="text-xl text-white font-semibold">Plus de détaille</span>
    <div class="flex flex-col mt-4">
        
        <!-- Categorie select options -->
        <div class="flex flex-row mt-5">
            <span class=" pl-5 text-green-500 font-semibold" v-text="'Catégorie:'"></span>
        </div>
        <div class="flex w-full pr-5 ">
            <div class="flex px-5 w-full">
                <input_ :inputs_="selectInput"></input_>
            </div>
        </div>
        <!-- Soucategorie select options -->
        <div class="flex flex-row mt-5">
            <span class=" pl-5 text-green-500 font-semibold" v-text="'Sous-Catégorie:'"></span>
        </div>
        <div class="flex w-full pr-5 ">
            <div class="flex px-5 w-full">
                <input_ :inputs_="selectInput2"></input_>
            </div>
        </div>
        <!-- Slider Progressbar -->
        <div class="flex flex-row mt-5">
            <span class=" pl-5 text-green-500 font-semibold" v-text="'De'"></span>
            <span class="px-3 text-green-500  " v-text="prix[0] "></span>
            <span class=" text-green-500 font-semibold" v-text="'à'"></span>
            <span class="pl-3 text-green-500  " v-text=" prix[2] +' Ar'"></span>
        </div>
        
        <div class=" px-5 flex items-center mt-3">
            <div class="flex flex-col w-full ">
                <div class="flex-flex-col  w-[93%]">
                    <div id="point" class="flex h-8 relative justify-between" @mousedown="lockMouse = true" @mouseup="lockMouse = false" @mousemove="updateSliderValue">
                        <span class="text-white text-xs font-semibold" v-text="prix[0]+' Ar'"> </span>
                        <div class=" mt-2 -ml-3 rounded-l-full rotate-45 flex items-center justify-center bg-stone-900 rounded-t-full z-0 absolute " :style="{ left: `${(sliderValue / 100) * 100}%` }">
                            <svg class="w-6 h-6  transform -rotate-45 rounded-l-full rounded-t-full   fill-current     text-white" viewBox="0 0 24 24">
                                <path d="M12 11.5A2.5 2.5 0 0 1 9.5 9 2.5 2.5 0 0 1 12 6.5 2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7z" /></svg>
                        </div>
                        <span class="text-white text-xs font-semibold" v-text="prix[1]+' Ar'"> </span>
                    </div>
                </div>
                <div class="w-[93%] bg-stone-500 h-1  rounded-sm " @mousedown="lockMouse = true" @mouseup="lockMouse = false" @mousemove="updateSliderValue">
                    <div class="h-full bg-green-500" :style="{ width: `${sliderValue}%` }"></div>
                </div>
            </div>
        </div>
        <!-- button -->
        <div class="flex items-center px-5 pr-10 mt-6 w-full justify-center">
            <button class=" w-full px-5 text-white bg-img-grad justify-center py-1 items-center flex flex-row text-center rounded-md">
                <svg class=" fill-current  w-5" viewBox="0 0 24 24">
                    <path d="M2 12a9 9 0 0 0 9 9c2.39 0 4.68-.94 6.4-2.6l-1.5-1.5A6.706 6.706 0 0 1 11 19c-6.24 0-9.36-7.54-4.95-11.95C10.46 2.64 18 5.77 18 12h-3l4 4h.1l3.9-4h-3a9 9 0 0 0-18 0z" /></svg>
                <span class=" font-semibold ml-3 uppercase text-xs">valider le recherche</span>
            </button>
        </div>
    </div>
    <span class="mt-12 text-xl text-white  font-semibold">Nos vendeurs</span>
 
    <div class="  overflow-y-auto h-[27vh] mt-2 max-h-full">
        <div v-for="item in this.$store.state.listFourn" :key="item.id" class="flex flex-col px-5 pr-10">
        <div class="border border-transparent bg-black group hover:border-green-500 mt-5 rounded-full px-2 py-1 flex flex-row items-center">
            <div v-if="getImg(item.fourn_img_log_middle) !== ''">
                <img :src="getImg(item.fourn_img_log_middle)" alt="" style="object-fit: cover" class="w-7 h-7 rounded-full" />
            </div>
            <div v-else>
                <svg class="fill-current w-7 h-7 text-white" viewBox="0 0 24 24"><path d="M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10c0-5.53-4.5-10-10-10z" /></svg>
            </div>
            <span v-text="item.fourn_name" class="group-hover:text-green-500 pl-3 text-white"></span>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import throttle from 'lodash/throttle';
import input_ from '@/components/input_.vue';
export default {
    components: {
        input_
    },
    data() {
        return {
            lockMouse: false,
            sliderValue: 0,
            isUpdatingUI: false,
            prix: [10000, 50000, 50000],
            selectInput: {
                model_: '',
                ico_: '<path d="M19 15h-2v-2h2m0 6h-2v-2h2M13 7h-2V5h2m0 6h-2V9h2m0 6h-2v-2h2m0 6h-2v-2h2m-6-6H5V9h2m0 6H5v-2h2m0 6H5v-2h2m8-6V5l-3-3-3 3v2H3v14h18V11h-6z" />',
                plh_: 'Tout',
                type_: 'select',
                array: ['Accessoire', 'Informatique', 'Quincallerie', 'Cuisine', 'Décoration', 'Mode']
            },
            selectInput2: {
                model_: '',
                ico_: '<path d="M19 15h-2v-2h2m0 6h-2v-2h2M13 7h-2V5h2m0 6h-2V9h2m0 6h-2v-2h2m0 6h-2v-2h2m-6-6H5V9h2m0 6H5v-2h2m0 6H5v-2h2m8-6V5l-3-3-3 3v2H3v14h18V11h-6z" />',
                plh_: 'Tout',
                type_: 'select',
                array: ['Souris', 'clavier', 'USB', 'Cable-Transfer', 'HDD', 'RAM']
            }
        };
    },
    methods: {
        updateSliderValue: throttle(function (e) {
            if (this.lockMouse && !this.isUpdatingUI) {
                const slider = this.$el.querySelector("#point");
                const rect = slider.getBoundingClientRect();
                const fullWidth = slider.offsetWidth;
                const offsetX = e.clientX - rect.left;
                const percent = (offsetX / fullWidth) * 100;

                // Ajuster la valeur de la slider par incréments de 100
                const sliderStep = 5;
                const numIncrements = Math.round(percent / sliderStep);
                this.sliderValue = Math.min(100, Math.max(0, numIncrements * sliderStep));

                // Ajuster la valeur de prix en fonction de la progression de la slider
                this.prix[2] = Math.round((this.sliderValue / 100) * (this.prix[1] - this.prix[0]) + this.prix[0]);

                // Demande une mise à jour de l'interface via requestAnimationFrame
                this.requestUpdateSliderUI();
            }
        }, 50),
        requestUpdateSliderUI() {
            if (!this.isUpdatingUI) {
                this.isUpdatingUI = true;
                requestAnimationFrame(() => {
                    this.isUpdatingUI = false;
                });
            }
        },
          getFournList() {
              this.$http.get('api/fournList').then((resp) => {
                this.$store.state.listFourn = resp.data.reponse;
            })
        },
        getImg(img) {
            try {
                return require(`./../../img/compressed/${img}`);
            } catch (e) {
                console.log(e); return ''
            }
        },
        getIt(e) {
            console.log(e);
        },
    },
    mounted() {
        this.getFournList();
        // this.throttledUpdateSliderValue = throttle(this.updateSliderValue, 50);

    },
    updated() {},
};
</script>

<style>
/* ... Styles ... */
</style>
