<template>
<div class=" w-full h-screen flex flex-col justify-between sm:h-full" :class="this.$store.state.theme_dark?'bg-gray ':''">
    <notif class=" z-[110]" v-if="$store.state.show_notif.show_==true"></notif>
    <tendance class=" z-[100]" v-if="$store.state.showTendance ==true"> </tendance>
    <!-- this.$store.state.tendeceListe  -->
    <div class="wfull flex flex-col justify-between sm:h-full">
        <navigation v-if="$store.state.isLoggedIn==true" class="border-b border-b-gray-800 px-2 sm:px-7 "></navigation>
        <footerSearch v-if="$store.state.searchBar" class=" w-full mt-2 z-20 flex"></footerSearch>
        <div class="flex  h-screen  w-full flex-row  ">
            <horizonNav v-if="$store.state.isLoggedIn==true " class="hidden md:flex w-1/5" />
            <div v-if="$store.state.isLoggedIn==true " :class="$store.state.showMenu?'w-full h-screen flex sm:hidden ':'w-0 flex sm:hidden  z-20 h-screen sm:static '" class="absolute duration-100">
                <horizonNav v-if="$store.state.showMenu" class=" z-20 w-full h-full "> </horizonNav>
            </div>
            <router-view class=" w-full h-full" />
        </div>
    </div>
</div>
</template>

<script>
import horizonNav from './components/vericalalBar_.vue'
import navigation from './components/horizontalBar_.vue'
import footerSearch from './components/barDeRecherche_.vue'
import notif from './components/notification_.vue'
import tendance from './components/note/tendance_.vue'

export default {
    components: {
        navigation,
        horizonNav,
        footerSearch,
        notif,
        tendance,
    },
    data() {
        return {
            access: false
        };
    },
    methods: {

    },
    mounted() {

    },
    created() {
        this.$http.get('api/check').then((resp) => {
            this.$store.state.isLoggedIn = resp.data.status;
            this.$store.state.client_=resp.data.data;
            this.$http.get('api/categorie/' + resp.data.data.client_id).then((rep) => { 
                if (rep.data.length > 0) {
                    this.$store.state.tendeceListe = rep.data
                } else { 
                    this.$store.state.showTendance = true;
                }
            })
        })
        this.$http.get('api/prodList/' + 0).then((resp) => {
            this.$store.state.listeProd_ = [resp.data.reponse, resp.data.nb_total];
        })
        this.$http.get('api/fournList').then((resp) => {
            this.$store.state.listFourn = resp.data.reponse;
        })

    },
};
</script>

<style>
.shadowing {
    box-shadow: 0px 0px 15px rgb(175, 175, 175);
}

.bg-gray {
    background: rgb(23, 23, 23);
}

.text-gray {
    color: rgb(23, 23, 23);
}
</style>
