<template>
<div class="flex flex-col mt-7 pt-3  w-full">
    <span class="text-stone-500">Produit similaire</span>
    <div class="border-t border-gray-800 flex ">
        <div class="flex items-center space-x-4 overflow-x-auto ">
            <!-- Use v-for to iterate through items -->
            <div v-for="(item, i) in this.$store.state.listeProd_[0]" :key="i"  class=" h-[150px] flex-shrink-0 w-[30vw] sm:w-[300px] my-4 border border-black rounded-lg overflow-hidden">
                <!-- Replace router-link with a div -->
                <div @click="$router.push({ name: 'myProduct', query: { name: item.prod_label }, params: { id: item.prod_id } })" class=" group h-full flex flex-row items-center cursor-pointer ">

                    <div class="flex item-center h-full  group-hover:w-[30px] w-full sm:border z-10 sm:border-black relative teptep2_">
                        <div v-if="item.images[0].img_midle!=undefined" :style="`background-image:url(${set_img_(item.images[0].img_midle)})`" class=" group-hover:w-[30px] w-full  h-full bg-cover scale-100 group-hover:scale-110 hover:scale-[115rem] duration-300 flex justify-center items-center">
                        </div>
                    </div>

                    <div class="flex px-1 text-left relative w-0  group-hover:w-full duration-200 flex-col z-50">
                        <div class=" flex flex-col relative">
                            <div class="flex w-full flex-row cursor-pointer items-center py-2 px-3">
                                <svg v-if="set_img_(item.fourn_img_log_middle)==''" class=" w-9 h-9 text-white fill-current" viewBox="0 0 24 24">
                                    <path d="M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10c0-5.53-4.5-10-10-10z" /></svg>
                                <div v-else :style="`background-image:url(${set_img_(item.fourn_img_log_middle)})`" class="h-9 w-9 rounded-full bg-cover flex justify-center items-center">
                                </div>
                                <div class="flex flex-col justify-center">
                                    <span class=" font-bold ml-1 text-green-400 text-sm" v-text="item.fourn_name"></span>
                                    <span class=" text-xs text-gray-500 ml-1  " v-text="item.prod_date_enreg"></span>
                                </div>
                            </div>
                            <div class=" ml-3 mt-1 flex flex-row items-center justify-between">
                                <div class="flex text-left mt-1 flex-col">
                                    <span class=" text-gray-400 text-xs" v-text="'Prix:'"></span>
                                    <span class=" text-white font-bold" v-text="item.prod_prix+' Ar'"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    methods: {
        set_img_(val) {
            try {
                return require(`./../../img/compressed/${val}`);
            } catch (e) {
                console.log(e); return '';
            }
        },
        cut_descript(val) {
            if (val.length > 100) {
                return val.substring(0, 97) + '...';
            }
            return val;
        },
    },
};
</script>

<style scoped>
/* Add CSS styles for horizontal scroll
.overflow-x-scroll {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    white-space: nowrap;
}

/* Add space between scroll items
.space-x-4>* {
    margin-right: 1rem;
}
 */
/* Add smooth scrolling 
.overflow-x-scroll::-webkit-scrollbar {
    height: 4px;
}

.overflow-x-scroll::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 2px;
}

.overflow-x-scroll::-webkit-scrollbar-track {
    background-color: transparent;
} */

/* Adjust these styles according to your design */
</style>
