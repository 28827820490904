<template>
<div v-if="template.type_!='select'" class="flex mt-2 flex-row group px-4 rounded-lg w-full  border border-stone-500">
    <svg v-html="template.ico_" class=" w-4 fill-current  text-stone-500" viewBox="0 0 24 24"> </svg>
    <input v-if="!template.is_white && !template.is_select" v-model="template.model_" :min="template.type_=='number'?0:''" :type="template.type_" :placeholder="template.plh_" class=" py-1 px-1 w-full text-white bg-transparent outline-none">
    <input v-else-if="template.is_white && !template.is_select" v-model="template.model_" :type="template.type_" :placeholder="template.plh_" class=" py-1 px-1 w-full text-black bg-transparent outline-none">
</div>
<div v-else class="flex mt-2 flex-row group px-4 rounded-lg w-full  border border-stone-500">
    <svg v-html="template.ico_" class=" w-4 fill-current  text-stone-500" viewBox="0 0 24 24"> </svg>
    <select v-model="template.model_"  name="" id="" class=" bg-transparent text-white w-full py-2 outline-none">
        <option class=" bg-stone-900 peer-hover:bg-red-500" value="" v-text=" template.plh_"></option> 
        <option class=" bg-stone-900 peer-hover:bg-red-500"  v-for="item,i in template.array " :key="i" :value="i"> {{ item }}
        </option>
    </select>
</div>
</template>

<script>
export default {
    props: {
        inputs_: {}
    },
    data() {
        return {
            template: this.inputs_
        }
    },
    beforeCreate() {}
}
</script>

<style>

</style>
[
