import { createRouter, createWebHashHistory, } from "vue-router";
// import store from '../store'
import produit from "../views/produit_.vue";
import login from '../views/authentification/connexion_.vue';
import inscription from '../views/authentification/inscription_.vue';
import profil from "../views/configuration_.vue";
import productProperty from "../views/liste/myProduct_.vue";
import abonnement from "../views/abonement_.vue";
import introuvable from "../views/introugable_.vue";
import axios from "axios";
var isLoggedIn = null;
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'http://localhost:4044'
const routes = [
    {
        path: "/produit",
        name: "produit",
        component: produit,
        meta: { requiresAuth: true },
        children: [{
            path: "/produit/:id",
            name: "myProduct",
            component: productProperty,
        },

        {
            path: "/:catchAll(.*)",
            redirect: "/noteFound",
        },]
    },
    {
        path: "/profil",
        name: "profil",
        component: profil,
        meta: { requiresAuth: true },
        children: [{
            path: "/profil/:id",
            name: "thisFourn",
            component: productProperty,
        }]
    },
    {
        path: "/",
        redirect: "/produit",
    },
    {
        path: "/abonnement",
        name: "abonnement",
        component: abonnement,
        meta: { requiresAuth: true },
    },
    {
        path: "/login",
        name: "connexion",
        component: login,
    },
    {
        path: "/noteFound",
        name: "noteFound",
        component: introuvable,
    },
    {
        path: "/inscription",
        name: "inscription",
        component: inscription,
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/noteFound",
    },

];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});



router.beforeEach(async (to, from, next) => {
    // si isLoggedIn = null il va attendre la verification dans le server pour affiche si l'utilisateur est connecté
    if (isLoggedIn == null) { 
        const resp = await axios.get('api/check');
        isLoggedIn = resp.data.status; 
    }
    // si non il n'attend plus la reponse du server mais passe à la lecture des route
    if (to.path === '/login') {
        if (isLoggedIn) {
            next('/produit');
        } else {
            next();
        }
    } else if (to.path === '/') {
        if (isLoggedIn) {
            next('/produit');
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (isLoggedIn === false) {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});
export default router;